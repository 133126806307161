@import "../style/style.css";
.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
h1,
h2 {
  margin: 0;
  padding: 0;
}
.article {
  width: 1440px;
  margin: 0 auto;
  padding-top: 90px;
  margin-top: 2.604166666666667vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.article .contianner {
  display: flex;
}
.article .main-left {
  margin-left: 2.604166666666667vw;
  padding-bottom: 50px;
}
.article .main-left .entry-header .entry-title {
  font-weight: bold;
}
.article .main-left .entry-header .time {
  margin-top: 0.4166666666666667vw;
  font-size: 0.9375vw;
  line-height: 1.3020833333333335vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.article .main-left .entry-content /deep/ .wp-block-image {
  max-width: 100%;
  margin: 0;
  margin-bottom: 1em;
}
.article .main-left .entry-content /deep/ .wp-block-image img {
  max-width: 100%;
}
.article .main-left .entry-content /deep/ .wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto;
}
.article .main-left .entry-content /deep/ .wp-block-image .aligncenter img {
  max-width: 100%;
}
.article .article_tags {
  margin-top: 4.21875vw;
  display: flex;
  justify-content: space-between;
}
.article .article_tags .tags {
  display: flex;
  align-items: center;
}
.article .article_tags .tags_lis {
  display: flex;
  cursor: pointer;
}
.article .article_tags .tags_lis .tags_item {
  margin-right: 1.0416666666666665vw;
  height: 2.083333333333333vw;
  padding-left: 0.5208333333333333vw;
  padding-right: 0.5208333333333333vw;
  line-height: 2.083333333333333vw;
  border-radius: 0.20833333333333334vw;
  background: #f7f7f7;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.article .article_tags .tags_lis .tags_item a {
  text-decoration: none;
  font-size: 0.7291666666666666vw;
  line-height: 1.0416666666666665vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.article .article_tags .tags_lis .tags_item .orgColor {
  color: #fff;
}
.article .article_tags .tags_lis .tags_item:hover {
  background: #ff6600;
}
.article .article_tags .tags_lis .tags_item:last-of-type {
  margin-right: 0;
}
.article .article_tags .tags_lis .tags_item:hover a {
  color: #fff;
}
.article .article_tags .label {
  font-size: 0.8333333333333334vw;
  line-height: 1.3020833333333335vw;
  cursor: pointer;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.article .share ul {
  display: flex;
}
.article .share ul .share_lis {
  padding-right: 1.0416666666666665vw !important;
  cursor: pointer;
}
.article .share ul .share_lis img {
  display: block;
  width: 2.2916666666666665vw;
  height: 2.2916666666666665vw;
}
.article .share ul .wx,
.article .share ul .qw {
  position: relative;
}
.article .share ul .wx .box,
.article .share ul .qw .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 13.020833333333334vw;
  height: 13.020833333333334vw;
  background-color: #f7f7f7;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-40%, -110%);
}
.article .share ul .wx .box .title,
.article .share ul .qw .box .title {
  font-size: 0.8333333333333334vw;
  padding-top: 1.0416666666666665vw;
  padding-bottom: 1.0416666666666665vw;
}
.article .share ul .wx #qrcode /deep/ img,
.article .share ul .qw #qrcode /deep/ img,
.article .share ul .wx #qrcodeCopy /deep/ img,
.article .share ul .qw #qrcodeCopy /deep/ img {
  display: block;
  width: 8.333333333333332vw;
  height: 8.333333333333332vw;
}
.article .share ul .wx .db,
.article .share ul .qw .db {
  display: none !important;
}
.article .entry-title {
  font-size: 30px;
}
.article /deep/ p {
  font-size: 16px;
  line-height: 2.083333333333333vw;
  line-height: 2;
  font-family: PingFangSC-Regular, PingFang SC;
}
.article /deep/ video {
  width: 100% !important;
}
.article /deep/ img {
  display: block;
  margin: 0 auto;
  width: 80%;
  text-align: center;
}
.article /deep/ figcaption {
  text-align: center;
}
.article /deep/ h1 {
  font-size: 2.44em;
}
.article /deep/ h2 {
  font-size: 1.95em;
}
.article /deep/ h3 {
  font-size: 1.56em;
}
.article /deep/ h4 {
  font-size: 1.25em;
}
.article /deep/ h5 {
  font-size: 1em;
}
.article /deep/ h6 {
  font-size: 0.8em;
}
.article .sidebar {
  margin-left: 4.166666666666666vw;
  width: 23.4375vw;
  align-self: right;
}
.article .sidebar .widget_categories {
  overflow: hidden;
  width: 20.833333333333336vw;
  padding-top: 1.8229166666666667vw;
  padding-left: 1.5625vw;
  padding-bottom: 3.125vw;
  padding-right: 1.5625vw;
  box-sizing: border-box;
  border-radius: 0.8333333333333334vw;
  background: #ffffff;
  box-shadow: 0px 2px 24px 1px rgba(0, 0, 0, 0.1);
}
.article .sidebar .widget_categories .new_list {
  margin-top: 1.0416666666666665vw;
}
.article .sidebar .widget_categories .new_list a {
  display: flex;
  text-decoration: none;
  align-items: center;
}
.article .sidebar .widget_categories .new_list .new_img {
  width: 5.625vw;
  height: 3.177083333333333vw;
  border-radius: 0.20833333333333334vw;
  margin-right: 0.5208333333333333vw;
}
.article .sidebar .widget_categories .new_list .new_img img {
  width: 5.625vw;
  height: 3.177083333333333vw;
  display: block;
  object-fit: cover;
  border-radius: 4px;
}
.article .sidebar .widget_categories .new_list .new_des {
  font-size: 0.9375vw;
  width: 14.583333333333334vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
}
.article .sidebar .widget_categories .new_list .new_des span {
  display: -webkit-box;
  font-weight: 600;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5;
}
.article .sidebar .widget_categories .widget-title {
  padding-bottom: 0.5208333333333333vw;
}
.article .sidebar .moc_knowledge {
  cursor: pointer;
  background: url(https://mktv-in-cdn.mockuai.com/16347881491189021.png) no-repeat;
  background-size: contain;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 24px 1px;
  box-sizing: border-box;
  width: 20.833333333333336vw;
  height: 21.25vw;
  border-radius: 0.5729166666666666vw;
  margin-top: 2.083333333333333vw;
  padding-top: 1.8229166666666667vw;
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
  padding-bottom: 3.125vw;
}
.article .sidebar .moc_knowledge .moc_title {
  font-size: 1.25vw;
}
.article .sidebar .moc_knowledge .knowledge_title {
  color: #ff6600;
}
.article .sidebar .moc_knowledge .knowledge_title a {
  font-size: 1.25vw;
  line-heiht: 1.7187500000000002vw;
  text-decoration: none;
  color: #ff6600;
  font-family: DIN-MediumItalic, DIN;
  display: flex;
  align-items: center;
}
.article .sidebar .moc_knowledge .knowledge_title .img {
  width: 0.3645833333333333vw;
  height: 0.625vw;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAYCAYAAADKx8xXAAAAAXNSR0IArs4c6QAAAPRJREFUOE+d1D1qAkEUwPH/CySpUnmRFF4gaCWINmohpNwtQlpvEtA9gJgEYuMZPIK9lSAEUqUIyAsziSbD7uzMOPX8eB8z74lOuOGDgxR8knBEM7YIV0BLZmxirYEvwABhD9zFYtFHrvniDaWTgsWkdg628Bx8gqnYgSm4BGNxJYzBXhjCtbASK7dSsAtCi3NGKAv7bhd0ZcoqCDWnh/IKXCI802IsQw71NXqQCezvag3yQl96/0eu/HMCkY7Y/asZfbDz6TSiarj/piMBnWrURGSh5jRR1jHpOc35jbYE5rS5N48bs7B+VscDDXniPQYc73wDVriOGWZFkkcAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 0.5208333333333333vw;
}
.article .sidebar .moc_knowledge .knowledge_title :hover .moc_title {
  font-weight: 600;
  color: #2e6fed;
}
.article .sidebar .moc_knowledge .knowledge_title :hover .img {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAYCAYAAADKx8xXAAAAAXNSR0IArs4c6QAAARFJREFUOE9jtC77z/uDi+Hv2QbGbwwkAEa9/HcPGRj+szGzsDmf7+W9RqxeRr38t6sYGBhCGRkZXjExszkSq5nRc9J/9qd3369jYPjvRYpmRpDTyNEM1kiOZrhGUjWjaCRFM4ZGYjVj1UiMZpwaCWnGqxGbZhYGDoOzE7ifE9QI0qyf/zb8PwPDChCbkZnR92Kf0BaCGg0K3/v///d/9X+G/6yMjIwr1awEo1eHMf7FqxGXJrDNuHIDPk04NRLShFUjMZowNBoUvA/4////KvSAwOYduB9J0QS3kVRNYI2GhR9M/v37d4wY5yE7mRFk27///9YzMDAuU7cWjANFLjEFFtiPZpWfhE+1870lRgNMDQBj/uIZ6VqAPwAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: contain;
}
.article .sidebar .moc_knowledge .knowledge_lis:nth-of-type(1) .left {
  background: red;
}
.article .sidebar .moc_knowledge ul :nth-child(2) .left {
  background: #ee6a0c;
}
.article .sidebar .moc_knowledge ul :nth-child(3) .left {
  background: #f9ab0d;
}
.article .sidebar .moc_knowledge .knowledge_main a {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.article .sidebar .moc_knowledge .knowledge_main .knowledge_lis .left {
  width: 1.3020833333333335vw;
  border-radius: 0.20833333333333334vw;
  font-size: 0.9375vw;
  margin-right: 0.5208333333333333vw;
  background: #dddddd;
  text-align: center;
  font-family: DIN-MediumItalic, DIN;
  font-weight: normal;
  color: #ffffff;
}
.article .sidebar .moc_knowledge .knowledge_main .knowledge_lis .right {
  flex: 1;
  font-size: 0.8333333333333334vw;
  line-height: 1.1458333333333333vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.article .sidebar .moc_knowledge .knowledge_main :nth-child(2) .left {
  background: #ee6a0c;
}
.article .sidebar .moc_knowledge .knowledge_main :nth-child(3) .left {
  background: #f9ab0d;
}
.article .sidebar .moc_knowledge .knowledge_lis:nth-of-type(1) {
  margin-top: 2.03125vw;
}
.article .sidebar .moc_knowledge .knowledge_lis:nth-of-type(1) .left {
  background: red;
}
.article .sidebar .moc_knowledge .knowledge_lis {
  margin-bottom: 1.3020833333333335vw;
}
.article .sidebar .Follow_moc {
  width: 20.833333333333336vw;
  height: 23.333333333333332vw;
  border-radius: 0.8333333333333334vw;
  margin-top: 2.2916666666666665vw;
  padding-top: 1.8229166666666667vw;
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
  margin-bottom: 2.604166666666667vw;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
}
.article .sidebar .Follow_moc .follow_title {
  font-size: 1.25vw;
  line-height: 1.7187500000000002vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.article .sidebar .Follow_moc .erm {
  text-align: center;
  margin-top: 1.8229166666666667vw;
}
.article .sidebar .Follow_moc .erm img {
  width: 12.5vw;
  height: 12.5vw;
}
.article .sidebar .Follow_moc .erm .text {
  font-size: 0.9375vw;
  margin-top: 1.0416666666666665vw;
  line-height: 1.3020833333333335vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.article .sidebar .hoverColor {
  color: #2e6fed !important;
}
@media screen and (max-width: 960px) {
  .article .main-left {
    width: 560px;
  }
  .article .contianner {
    margin-right: 2.083333333333333vw;
    margin-left: 2.083333333333333vw;
  }
}
@media screen and (min-width: 960px) and (max-width: 1230px) {
  .article .main-left {
    width: 560px;
  }
  .article .contianner {
    margin-right: 7.291666666666667vw;
    margin-left: 7.291666666666667vw;
  }
}
@media screen and (min-width: 1230px) and (max-width: 1440px) {
  .article .main-left {
    width: 720px;
  }
  .article .contianner {
    margin-right: 7.291666666666667vw;
    margin-left: 7.291666666666667vw;
  }
}
@media screen and (min-width: 1440px) {
  .article .main-left {
    width: 800px;
    margin-right: 0vw;
    margin-left: 0vw;
  }
  .article .entry-title {
    font-size: 36px;
  }
  .article .time {
    font-size: 18px;
  }
  .article .article_tags .tags_lis .tags_item {
    margin-right: 20px;
    height: 40px;
    width: 80px;
    padding: 0 10px;
    background: #f7f7f7;
    line-height: 40px;
    text-align: center;
  }
  .article .article_tags .tags_lis .tags_item a {
    font-size: 14;
  }
  .article .article_tags .label {
    font-size: 16px;
  }
  .article .share ul .share_lis {
    padding-right: 20px;
  }
  .article .share ul .share_lis img {
    width: 44px;
    height: 44px;
  }
  .article .share ul .wx .box,
  .article .share ul .qw .box {
    width: 250px;
    height: 250px;
  }
  .article .share ul .wx .box .title,
  .article .share ul .qw .box .title {
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .article .share ul .wx #qrcode /deep/ img,
  .article .share ul .qw #qrcode /deep/ img,
  .article .share ul .wx #qrcodeCopy /deep/ img,
  .article .share ul .qw #qrcodeCopy /deep/ img {
    display: block;
    width: 160px;
    height: 160px;
  }
  .article .share ul .wx .db,
  .article .share ul .qw .db {
    display: none !important;
  }
  .article .sidebar {
    margin-left: 80px;
    width: 450px;
  }
  .article .sidebar .widget_categories {
    width: 400px;
    padding-top: 35px;
    padding-left: 30px;
    padding-bottom: 60px;
    padding-right: 30px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 2px 24px 1px rgba(0, 0, 0, 0.1);
  }
  .article .sidebar .widget_categories .new_list {
    margin-top: 20px;
  }
  .article .sidebar .widget_categories .new_list a {
    display: flex;
  }
  .article .sidebar .widget_categories .new_list .new_img {
    width: 108px;
    height: 61px;
    border-radius: 4px;
    margin-right: 10px;
  }
  .article .sidebar .widget_categories .new_list .new_img img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  .article .sidebar .widget_categories .new_list .new_des {
    font-size: 18px;
    flex: 1;
  }
  .article .sidebar .widget_categories .widget-title {
    padding-bottom: 10px;
  }
  .article .sidebar .moc_knowledge {
    width: 400px;
    height: 408px;
    font-size: 18px;
    margin-top: 40px;
    padding: 35px 30px 60px;
  }
  .article .sidebar .moc_knowledge .moc_title {
    font-size: 24px;
  }
  .article .sidebar .moc_knowledge .knowledge_main .knowledge_lis {
    margin-bottom: 20px;
  }
  .article .sidebar .moc_knowledge .knowledge_main .knowledge_lis a .right {
    font-size: 18px;
  }
  .article .sidebar .moc_knowledge .knowledge_main .knowledge_lis a .left {
    width: 25px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
  }
  .article .sidebar .moc_knowledge .knowledge_lis:nth-of-type(1) {
    margin-top: 30px;
  }
  .article .sidebar .moc_knowledge .knowledge_lis:nth-of-type(1) .left {
    background: red;
  }
  .article .sidebar .Follow_moc {
    width: 400px;
    height: 448px;
    border-radius: 16px;
    margin-top: 44px;
    padding: 35px 30px 0;
  }
  .article .sidebar .Follow_moc .follow_title {
    font-size: 24px;
  }
  .article .sidebar .Follow_moc .erm {
    margin-top: 35px;
  }
  .article .sidebar .Follow_moc .erm img {
    width: 240px;
    height: 240px;
  }
  .article .sidebar .Follow_moc .erm span {
    font-size: 18px;
    margin-top: 20px;
  }
}
